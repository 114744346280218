/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '~bootstrap/scss/functions';
@import '~@mos/dashkit/scss/variables';
@import '~bootstrap/scss/bootstrap';
@import '~@mos/dashkit/scss/dashkit';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

:root {
  --theme-primary: #003b75;
  --theme-primary-rgba: 0, 59, 117;
  --theme-secondary: #004e9a;
  --theme-accent: #1c72e3;
  --theme-accent-rgba: 28, 114, 227;
  --theme-green: #397942;
  --theme-background: #edf4fd;
  --theme-header-bg: #003b75;
  --theme-header-title: #ffffff;
}

$color--blue: #1452a3 !default;
$color--blue-dark: #003b75 !default;

$color--gray: #91989c !default;
$color--gray-dark: #6f7578 !default;
$color--gray-light: #e3e9ec !default;

$color--green: #5bb45e !default;
$color--green-dark: #397942 !default;

$color--teal: #5cacaa !default;
$color--teal-dark: #437f7e !default;

$color--chilli: #b70e0c !default;
$color--chilli-dark: #8a0807 !default;

$color--ruby: #dc7995 !default;
$color--ruby-dark: #a15168 !default;

$color--mustard: #d7af00 !default;
$color--mustard-dark: #7d6600 !default;

$color--lavender: #9983be !default;
$color--lavender-dark: #6c5b8b !default;

$color--white: #ffffff !default;
$color--black: #000000 !default;
$color--silver: #ebf0f9 !default;

$color--accent: #1c72e3 !default;
$color--field-border: #486587 !default;
$color--inactive: #9ab1cd !default;
$color--border: #d2deed !default;
$color--backdrop: #ecf0f9 !default;

// Color palette
$color-palette: (
  'blue': $color--blue,
  'blue-dark': $color--blue-dark,
  'gray': $color--gray,
  'gray-dark': $color--gray-dark,
  'gray-light': $color--gray-light,
  'green': $color--green,
  'green-dark': $color--green-dark,
  'teal': $color--teal,
  'teal-dark': $color--teal-dark,
  'chilli': $color--chilli,
  'chilli-dark': $color--chilli-dark,
  'ruby': $color--ruby,
  'ruby-dark': $color--ruby-dark,
  'mustard': $color--mustard,
  'mustard-dark': $color--mustard-dark,
  'lavender': $color--lavender,
  'lavender-dark': $color--lavender-dark,
  'white': $color--white,
  'black': $color--black,
  'silver': $color--silver,
  'accent': $color--accent,
  'field-border': $color--field-border,
  'inactive': $color--inactive,
  'border': $color--border,
  'backdrop': $color--backdrop,
) !default;

$primary: $color--blue-dark !default;
$secondary: $color--blue !default;
$success: $color--green-dark !default;
$info: $color--gray-dark !default;
$warning: $color--mustard !default;
$danger: $color--chilli-dark !default;
$light: $color--white !default;
$dark: $color--black !default;

// Theme color
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;

/** bootstrap overrides **/
$body-color: #003b75;

/** Dashkit overrides **/
$card-box-shadow: 0 0.5rem 1rem rgba($color--black, 0.15);
$size-xs: 767px; // bootstraps md width (used as col-md-5)
$aps-button-colour: #1452a3;

* {
  font-family: 'Raleway', sans-serif;
}

body {
  background: white;
  margin: 0;
  padding: 0;
}

.page-title {
  color: var(--theme-primary);
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 57px;
  text-align: center;
}

.nav-tabs {
  border-bottom: 1px solid var(--theme-primary)!important;
}

.nav-tab-title {
  padding: 0 !important;
  color: var(--theme-primary)!important;
  font-size: 20px !important;
  letter-spacing: 0.25px !important;
  line-height: 24px !important;
  margin-bottom: 0;
}

.section-title {
  color: var(--theme-primary)!important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 24px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.icon {
  // fill: var(--theme-primary)!important;
  // filter: brightness(0) saturate(100%) invert(19%) sepia(33%) saturate(5174%) hue-rotate(195deg) brightness(99%) contrast(102%);
  font-size: 24px;
}

.material-icons {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  // -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  color: var(--theme-primary);
  transition: $transition-base;

  &-outlined {
    @extend .material-icons;
    font-family: 'Material Icons Outlined', sans-serif !important;
  }

  &-filled {
    @extend .material-icons;
    font-family: 'Material Icons', sans-serif !important;
  }
}
$font-family-sans-serif: 'Raleway', sans-serif !default;
$font-path: 'assets/fonts/raleway' !default;
// colors
$color-gray: #ddd;
// spaces
$space-medium: 10px;
body {
  overflow-x: hidden;
}
.hoverable-text:hover {
  font-weight: bold;
  cursor: pointer;
}

*:focus {
  outline: none;
}

*:focus-visible {
  outline: var(--theme-primary) solid!important;
}

.aps-button {
  @extend .btn-secondary;
  background-color: var(--theme-primary)!important;
  color: #ffffff !important;
  font-weight: bold !important;
  padding: 8px !important;
  border: none !important;
}

.aps-button:hover {
  filter: brightness(90%)!important;
  border: none !important;
}

.aps-button:focus {
  background-color: var(--theme-primary)!important;
  color: #ffffff !important;
}

.aps-button-outlined {
  font-weight: bold !important;
  background-color: white!important;
  border-color: var(--theme-primary)!important;
  color: var(--theme-primary)!important;
}

.aps-button-outlined:hover {
  background-color: var(--theme-primary)!important;
  color: #ffffff !important;

  .material-icons-outlined,
  .material-icons-filled,
  .icon {
    color: #ffffff !important;
  }
}

.aps-button-outlined:focus {
  background-color: var(--theme-primary)!important;
  color: #ffffff !important;

  .material-icons-outlined,
  .material-icons-filled,
  .icon {
    color: #ffffff !important;
  }
}

.modal-backdrop {
  display: none;
}

#requestModal {
  background: rgba(0, 0, 0, 0.5);
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
